function closeNav() {
  $('.mobile-nav-trigger,.menu-toggle').removeClass('nav-is-visible');
  $('.mobile-primary-nav,.menu-toggle').removeClass('nav-is-visible');
  $('.has-children .menu-page').addClass('is-hidden');
  $('.has-children a').removeClass('selected');

  $('.moves-out').removeClass('moves-out');
  $('body,html').removeClass('nav-is-visible');
  $('.l-page').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
  
  });
}


jQuery(document).ready(function($){

  //mobile - open lateral menu clicking on the menu icon
  $('.mobile-nav-trigger, .mobile-nav-trigger span,.menu-toggle').on('click', function(event){
    event.preventDefault();
    event.stopPropagation();
    $this = $(this);
    if($(this).is("span")){
      $this = $(this).parent();
    }
    if( $('.l-page').hasClass('nav-is-visible') ) {
      closeNav();
      $('.mobile-overlay').removeClass('is-visible');
    } else {
      $('.mobile-nav-trigger, .mobile-nav-trigger span').addClass('nav-is-visible');
      $('.mobile-primary-nav').addClass('nav-is-visible');
      $("body,html").addClass('nav-is-visible');
      $('.l-page').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){

      });
    }
  });
  
 
  
  //open submenu
  //$('.has-children').children('.menu-page-trigger').on('click', function(event){
  $('.has-children').children('a').on('click', function(event){ 
    //var selected = $(this).siblings('a');
    var selected = $(this);
    if( selected.next('.menu-page').hasClass('is-hidden') ) {
      //desktop version only
      selected.addClass('selected').next('.menu-page').removeClass('is-hidden').end().parent('.has-children').parent('.menu-page').addClass('moves-out');
      selected.parent('.has-children').siblings('.has-children').children('.menu-page').addClass('is-hidden').end().children('a').removeClass('selected');
    } else {
      selected.removeClass('selected').next('.menu-page').addClass('is-hidden').end().parent('.has-children').parent('.menu-page').removeClass('moves-out');
    }
  });

  //submenu items - go back link
  $('.go-back').on('click', function(){
    $(this).parent('.menu-page').addClass('is-hidden').parent('.has-children').parent('.menu-page').removeClass('moves-out');
  });
  
  
  $("a.jump").on('click', function(e){
      var menuId = "section."+$(this).attr('rel');
      var $id = $(menuId);
      if($id.length > 0){
        e.preventDefault();
        e.stopPropagation();
        closeNav();
        //$id.velocity("scroll", { duration: 1200, easing: "easeInSine" });
        var offset = ($id.offset().top) +"px";
        $("html").velocity("scroll", { offset: offset, mobileHA: false,duration: 800, easing: "easeInSine" });
      }else{
        closeNav();
        //window.location = $(this).attr('href');
      }
  });
  $('html').click(function(e) {
    if($(e.target).closest('.mobile-primary-nav').length === 0 && $('.l-page').hasClass('nav-is-visible') && !$(e.target).is('.mobile-nav-trigger')){
      closeNav();
    }
  });
  
  $('#mobile-primary-nav').click(function(event){
      event.stopImmediatePropagation();
  });
        

  $( window ).on( "orientationchange resize", function( event ) {   closeNav(); });

  // function mouseIn(e){
  //   //console.log("in");
  //   $(e.currentTarget).addClass('over');
  //   //$(e.currentTarget).find('.dropdown-menu').velocity("slideDown", { });
  // }
  // function mouseOut(e){
  //   $(e.currentTarget).removeClass('over');
  //   // $(e.currentTarget).find('.dropdown-menu').velocity("slideUp", { });
  // }
  // $("#menu-primary-menu .dropdown").hoverIntent(mouseIn, mouseOut);

});