/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
 if ('addEventListener' in document) {
    document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
    }, false);
}
Modernizr.addTest('svgasimg', document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $(window).load(function(){
          //viewportUnitsBuggyfill.init({refreshDebounceWait: 250});
        });
        if(!Modernizr.svgasimg){
          $('img').each(function() {
            var img_src = $(this).attr('src');
            var new_src = img_src.replace(/\.svg$/, '.png');
            $(this).attr('src', new_src);
          });
        }
        if(!Modernizr.touch){
          $("a[href*='tel:']").on('click',function(){
            return false;
          });
        }
        if(Modernizr.input.placeholder){
          $(".gform label").hide();
        }
        $(".comment-form input,.comment-form textarea").on('focus',function(){
          $(this).parent().addClass('input-focus');
        });
        $(".comment-form input,.comment-form textarea").on('blur',function(){
          $(this).parent().removeClass('input-focus');
        });
        $('.slider-items').slick({
          mobileFirst: true,
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          variableWidth: false,
          useCSS:true,
          useTransform:true,
          cssEase:'ease',
          responsive: [
          {
            breakpoint: 600,
            settings: {
              arrows:true,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows:true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        });
        $('.gallery .item,.gallery-item-open').magnificPopup({
          type: 'image',
          // Delay in milliseconds before popup is removed
          removalDelay: 300,

          // Class that is added to popup wrapper and background
          // make it unique to apply your CSS animations just to this exact popup
          mainClass: 'mfp-fade',
          image:{
            titleSrc: 'title',
          },
          gallery:{
            enabled:true
          },
          callbacks:{
            open:function(){
              $(".mfp-content").swipe({
                swipeLeft: function(event, direction, distance, duration, fingerCount) {
                    $(".mfp-arrow-left").magnificPopup("prev");
                    
                },
                swipeRight: function() {
                    $(".mfp-arrow-right").magnificPopup("next");
                },
                threshold: 50
              });
            }
          }
        });
        
      },

      finalize: function() {
        $(".listing .item").matchHeight();
        window.requestAnimationFrame(function(){
          document.body.scrollTop = 0;
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(".hero.front p")
        // Blast the text apart by word.
        .blast({ delimiter: "word" })
        // Fade the words into view using Velocity.js.
        .velocity("transition.slideDownIn", { 
          display: null,
          duration: 1500,
          stagger: 115.384615385,
          delay: 450
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        var $bg = $('.hero .bgholder');
        var scrollVar = $(window).scrollTop();
        var docheight = $(document).height(), winheight = $(window).height();
        var ticking = false;
        function heroScroll(){
          ticking = false;
          var limitedScrollVar = scrollVar*0.25;
          var opacity = 1-scrollVar / (winheight - 100);
          if($bg[0]){
            $bg[0].style.transform = 'translate3d(0px,'+(2*limitedScrollVar)+'px,0px)';
          }
        }
        heroScroll();
        function requestTick() {
          if(!ticking) {
            window.requestAnimationFrame(heroScroll);
          }
          ticking = true;
        }
        $(window).scroll(function(){
          scrollVar = $(window).scrollTop();
          requestTick();
        });
        var sticky = new Waypoint.Sticky({
          element: $('.l-region--header')[0]
        });
        
        $('.slider').slick({
          mobileFirst: true,
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 1,
          variableWidth: false,
          useCSS:true,
          useTransform:true,
          cssEase:'ease',
          responsive: [
          {
            breakpoint: 1900,
            settings: "unslick"
          },
          {
            breakpoint: 1250,
            settings: "unslick"
          },
          {
            breakpoint: 1023,
            settings: {
              arrows:true,
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 600,
            settings: {
              arrows:true,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows:true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }]
        });
        //fix slider not re-appearing
        $(window).resize(function() {
          $('.slider').slick('resize');
        });
        $(window).on('orientationchange', function() {
          $('.slider').slick('resize');
        });
        
      }

    },
    // About us page, note the change from about-us to about_us.
    'archive': {
      init: function() {
        // JavaScript to be fired on the about us page
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
