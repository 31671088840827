function gmapInit() {
  
  var image = '/app/themes/mhv/assets/images/pin.png';
  var lat = 48.424468 ;
  var lng = -123.364606;
  if($("#map-canvas").data('lat') !== undefined && $("#map-canvas").data('lng') !== undefined){
    lat = $("#map-canvas").data('lat');
    lng = $("#map-canvas").data('lng');
  }
  var myLatlng = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    zoom: 15,
    center: myLatlng,
    panControl: false,
    zoomControl: true,
    scaleControl: false,
    scrollwheel: false, 
    styles:[{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
  };
  if(Modernizr.touch){
    mapOptions.draggable = false;
  }
  var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: image
  });
  var currCenter = map.getCenter();
  google.maps.event.addDomListener(window, 'resize', function() {
    map.setCenter(currCenter);
  });
  google.maps.event.addDomListener(window, 'orientationchange', function() {
    map.setCenter(currCenter);
  });
}
var script ='https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit';
$(function(){
  if($("#map-canvas").length !== 0){ 
    console.log("get scripts");
    $.getScript( 'https://maps.googleapis.com/maps/api/js?v=3.exp&callback=gmapInit' )
      .done(function( script, textStatus ) {
        //console.log( script );
      })
      .fail(function( jqxhr, settings, exception ) {
        //$( "div.log" ).text( "Triggered ajaxError handler." );
    });
  }
});
