var windowOpen;
jQuery(document).on( 'ready post-load', function(){
  jQuery( 'a.share-google-plus-1' ).on( 'click', function() {
    if ( 'undefined' !== typeof windowOpen ){ // If there's another sharing window open, close it.
      windowOpen.close();
    }
    windowOpen = window.open( jQuery(this).attr( 'href' ), 'wpcomgoogle-plus-1', 'menubar=1,resizable=1,width=480,height=550' );
    return false;
  });
});
jQuery(document).on( 'ready post-load', function(){
  jQuery( 'a.share-linkedin' ).on( 'click', function() {
    if ( 'undefined' !== typeof windowOpen ){ // If there's another sharing window open, close it.
      windowOpen.close();
    }
    windowOpen = window.open( jQuery(this).attr( 'href' ), 'wpcomlinkedin', 'menubar=1,resizable=1,width=580,height=450' );
    return false;
  });
});
jQuery(document).on( 'ready post-load', function(){
  jQuery( 'a.share-facebook' ).on( 'click', function() {
    if ( 'undefined' !== typeof windowOpen ){ // If there's another sharing window open, close it.
      windowOpen.close();
    }
    windowOpen = window.open( jQuery(this).attr( 'href' ), 'wpcomfacebook', 'menubar=1,resizable=1,width=600,height=400' );
    return false;
  });
});
jQuery(document).on( 'ready post-load', function(){
  jQuery( 'a.share-twitter' ).on( 'click', function() {
    if ( 'undefined' !== typeof windowOpen ){ // If there's another sharing window open, close it.
      windowOpen.close();
    }
    windowOpen = window.open( jQuery(this).attr( 'href' ), 'wpcomtwitter', 'menubar=1,resizable=1,width=600,height=350' );
    return false;
  });
});